import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Flex, Box, Text, Alert, AlertIcon, Icon } from '@chakra-ui/core';

import { saveLocal } from '../../utils/functions';

export default function StartSplash() {
  const [state, setState] = useState({
    errors: false,
    loading: true,
  });

  const location = useLocation();
  const url = location.search;
  const searchParams = queryString.parse(url);
  const userId = searchParams.user_id;
  const userFirstName = searchParams.firstname;
  const userLastName = searchParams.lastname;
  const userPhone = searchParams.phone;

  useEffect(() => {
    const checkUrlParams = () => {
      if (!userId || !userFirstName || !userLastName || !userPhone) {
        setState({
          ...state,
          loading: false,
          errors: 'Please make sure you use the correct link from your email',
        });
      } else {
        saveLocalDetails();
      }
    };

    checkUrlParams();
  }, []);

  const saveLocalDetails = async () => {
    const userDetails = {
      id: userId,
      firstName: userFirstName,
      lastName: userLastName,
      phone: userPhone,
    };

    if (userDetails) {
      const saveIt = await saveLocal('userDetails', userDetails);

      try {
        saveIt();
      } catch (error) {
        setState({
          ...state,
          loading: false,
          errors: 'Something went wrong please try again',
        });
      }

      navigate('/01');
    } else {
      setState({
        ...state,
        errors:
          'Please make sure you use the correct link from your email -- save local details',
      });
    }
  };

  return (
    <Flex direction="column" w="100%">
      <Box>
        <Box>{state.loading && <Text>Loading...</Text>}</Box>
        <Box>
          {state.errors && (
            <Alert status="error" variant="left-accent">
              <AlertIcon />
              {state.errors}
            </Alert>
          )}
        </Box>
      </Box>
    </Flex>
  );
}
