import React from 'react';
import Layout from '../components/layouts/Layout';
import StartSplash from '../components/landing-pages/StartSplash';

export default function index() {
  return (
    <Layout>
      <StartSplash />
    </Layout>
  );
}
